

html {
  --Trigon-Middle: #00850E;
  --Trigon-Dark: #032705;
  --Trigon-Light: #DDE7DA;

  --font-size: 18px;
  --input-height: 40px;
  --base-space: 48px;
  --base-bg: #fff;
  --view-animation-time: 2s;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (min-width: 1000px) {
  input, button, textarea {
    font-size: inherit
  }
}
@media only screen and (max-width: 767px) {
   input, button, textarea {
    font-size: inherit
  }
}
select,
textarea,
input[type=tel],
input[type=email],
input[type=password],
input[type=text] {
  border: solid 2px var(--Trigon-Dark);
  padding: 0 calc(var(--base-space) / 2);
  background: var(--base-bg);
  outline: none;
  height: var(--input-height);
  line-height: var(--input-height);
  box-sizing: border-box;
  margin: 0 0 calc(var(--base-space)/2);
  color: var(--Trigon-Dark);
  display: block;
  width: 100%;
}
textarea:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=text]:focus {
  background: var(--base-bg);
  border-color: var(--Trigon-Dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.caps {
  text-transform: uppercase;
}