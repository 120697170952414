
@import '../../../node_modules/bootstrap/dist/css/bootstrap-grid.css';
@import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
@import './fonts/coconat-demi.css';
@import './fonts//neue-haas-gisplay.css.css';

:root {
  --app-height: 100%;
}

html {
  --Trigon-Light: #DDE7DA;
  --Color-Neutral-black: #000;
  --base-space: 48px;
}
@media only screen and (max-width: 767px) {
  html {
    --base-space: 16px;
  }
  .container {
    padding: 0;
  }
}

html, body {
  min-height: 100%;
  padding: 0;
  margin: 0;
  background: white;
  color: var( --Trigon-Dark);
}

html, body, input, textarea, select, button {
  font-style: normal;
  line-height: 27px;
  font-size: var(--font-size);
  font-family: 'Neue Haas';
  font-weight: 400;
  letter-spacing: 0.3px
}

.CookieConsent {
  align-items: center !important;
  a {
    color: #000;
  }
}

.small {
  font-size: calc(var(--font-size) - 4px);
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  html, body, input, textarea, select, button {
    line-height: 36px;
  }
}

h1,
h2 {
  font-family: Coconat;
}

h1 {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
  font-size: 56px;
  margin: var(--base-space) 0;
}
h2 {
  margin: calc(var(--base-space)/2) 0;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 40px;
  }
}
.mobile-only {
  display: none;
}
@media only screen and (max-width: 767px) {
  .mobile-only {
    display: block;
  }
  .mobile-hidden {
    display: none;
  }
}


.bank-button {
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .bank-button {
    display: block;
    margin: 25px auto;
    background-color: #fff;
    border-radius: 60px;
    img {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }
}

.button {
  display: flex;
  display: inline-block;
  box-sizing: content-box;
  height: var(--base-space);
  line-height: var(--base-space);
  padding: 0 calc(var(--base-space) / 2);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 48px;
  border: 2px solid var(--Trigon-Middle, #00850E);
  vertical-align: middle;
  margin: 0 10px;
  color: var(--Trigon-Light, #DDE7DA);
  text-decoration: none;
  font-weight: 600;
  text-decoration: none !important;
  &:hover {
    border: 2px solid var(--Trigon-Middle, #00850E);
    background: var(--Trigon-Middle, #00850E);
    color: var(--Trigon-Light, #DDE7DA);
  }
}

@media only screen and (max-width: 767px) {
  .button {
    display: block;
    width: 100%;
    margin: 0 auto 20px;
    box-sizing: border-box;
    height: var(--base-space);
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    border-radius: 40px;
    padding: 0 30px;
    text-align: center;
  }
}

.relative {
  position: relative;
}

.main-app {
  &.no-scroll {
    position: relative;
    overflow: hidden;
    max-height: var(--app-height);
  }
  .app-container {
    overflow: hidden;
    flex: 1;
    position: relative;
    padding-top: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .app-container {
    > .container {
      padding: 0;
    }
  }
}


.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    background: #fff;
    border-radius: 100%;
    padding: calc(var(--base-space)*4);
    text-align: center;
    min-width: var(--buble-size);
    min-height: var(--buble-size);
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 2.5rem;
      margin: 0;
    }

    p {
      font-size: 1.5rem;
      margin: var(--base-space) 0 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(var(--base-space)*2);
      left: calc(var(--base-space)*2);
      width: calc(100% - var(--base-space)*4);
      height: calc(100% - var(--base-space)*4);
      border: dashed 4px var(--blue);
      border-radius: 100%;
      animation: rotate 15s infinite linear;
    }

    &.bounce-out {
      animation: popOut var(--view-animation-time) cubic-bezier(0.775, -0.600, 0.000, 1.605) forwards;
    }
  }
}

.disclaimer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #032705eb;
  color: var(--Trigon-Light);
  z-index: 10000;
  padding: var(--base-space);
  box-sizing: border-box;

  .button {
    color: #000;
  }

  > div {
    display: block;
    position: relative;
    overflow: auto;
    max-width: 700px;
    margin: 0 auto;
    width: calc(100% - var(--base-space)*2);
    max-height: calc(var(--app-height) - var(--base-space)*2);
    padding: var(--base-space) var(--base-space) 0;
    box-sizing: border-box;
    background-color: #FFF;
    color: #000;


    .actions {
      text-align: center;
      position: sticky;
      bottom: 0;
      padding: calc(var(--base-space)/2) 0;
      margin-top: 40px;
      background-color: #FFF;
    }
  }
}
@media only screen and (max-width: 767px) {
  .disclaimer {
    > div {
      .actions {
        padding-bottom: var(--base-space);
        display: flex;
        gap: 12px;
      }
    }
  }
}
.head-bar {
  background: var(--Trigon-Middle, #00850E);
  display: flex;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 72px;
  padding: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  img {
    max-width: 90%;
  }
}
.section {
  padding: calc(var(--base-space) * 1.5);
}

.head-promo {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  background: var(--Trigon-Dark, #032705);
  color: var(--Trigon-Light, #DDE7DA);
  padding-top: var(--base-space);
  a {
    color: var(--Trigon-Light, #DDE7DA);
    text-decoration: underline;
  }
  h1 {
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
    text-align: left;
    margin: var(--base-space) 0 calc(var(--base-space)/2);
  }
  .hero {
    display: block;
    max-width: 100%;
    margin-left: var(--base-space);
    margin-bottom: calc(var(--base-space) * -1.5);
    margin-top: calc(var(--base-space) * -1.5);
    top: calc(var(--base-space) * 2);
    position: relative;
  }
  .lipper {
    position: absolute;
    left: 0;
    top: 44%;
    transform: translateX(-20%);
  }
  .hero-graph {
    position: absolute;
    right: 0;
    top: 60%;
    width: 50%;
  }
  .small {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .head-promo {
    .hero-mobile  {
      position: relative;
      margin: 30px 0 50px;
    }
    .lipper {
      transform: translateX(-5%);
      z-index: 2;
      max-width: 35%;
      top: 174px;
    }
    .hero-graph {
      top: 0;
      position: relative;
      width: 85%;
      margin: -100px 0 0 auto;
      display: block;
    }
    .container {
      padding: 0;
    }
    .hero {
      margin: 0;
      max-width: 40%;
      top: 0;
    }
  }
}


.profits {
  color: var(--Trigon-Dark, #032705);
  padding: calc(var(--base-space) * 1.5);

  h1 {
    font-size: 56px;
  }
}
@media only screen and (max-width: 767px) {
  .profits {
    padding-top: 80px;
    h1 {
      font-size: 36px;
    }
  }
}

.people {
   padding: calc(var(--base-space) * 1.5);
   padding-top: 40px;
   h1 {
    margin-top: 0;
   }
  .person {
    text-align: center;
    .photo {
      width: 130px;
      max-width: 100%;
      border-radius: 100%;
      margin: calc(var(--base-space) * 0.5) auto;
    }
    h3 {
      margin:0;
      color: var(--Color-Neutral-black, #000);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
    p {
      margin: 0 0 calc(var(--base-space) * 0.5) 0;
      font-size: 18px;
    }
  }
  .ppl-nav {
    text-align: right;
    display: flex;
    justify-content: space-between;
    .dots {
      padding: 20px;
      span {
        display: inline-block;
        width: 9px;
        height: 9px;
        background-color: var(--Trigon-Light, #DDE7DA);
        transform: scale(1.2);
        margin-left: 10px;
        border-radius: 100%;
        &.active {
          background-color: var(--Trigon-Dark, #032705);
        }
      }
    }
    .buttons {
      padding: 10px;
      gap: 10px;
      transform: scale(1.3);
    }
    button {
      margin: 10px 5px;
      width: 40px;
      height: 25px;
      padding: 5px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      border: 1px solid var(--Trigon-Light, #DDE7DA);
      opacity: 1;
      display: inline-block;
      position: relative;

      &::before {
        content: '';
        border: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(./arrow.svg) no-repeat center center;
        margin: 0;
      }
      &.prev {
        &::before {
          transform: rotate(180deg);
        }
      }
      &:disabled {
        border-color: #CCC;
        background: #CCC;
      }
    }
  }
}


@media only screen and (max-width: 767px) {
  .people {
    .scrollWrapper {
      overflow: hidden;
      white-space: nowrap;
      margin: 0 calc(var(--base-space) * -1.5);
      padding: 0 28%;

    }
    .row {
      min-width: 900px;
      overflow: auto;
      transition: transform 0.3s ease-in-out;
      &.start {
        transform: translateX(0);
      }
      &.middle {
        transform: translateX(-33%);
      }
      &.end {
        transform: translateX(-66%);
      }
    }
    .person {
      .photo {
        width: 200px;
      }
    }
  }
}
.row {
  &.flex
  > div {
    display: flex;

  }
}
.green-section {
  border-radius: 16px;
  background: var(--Trigon-Light, #DDE7DA);
  padding: var(--base-space);
  margin-bottom: calc(var(--base-space)/2);
  text-align: center;
  box-sizing: border-box;
  align-self: stretch;
  width: 100%;

  h2 {
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin: 0 ;
  }
}

@media only screen and (max-width: 767px) {
  .green-section {
    padding: calc(var(--base-space)*2);
    font-size: 24px;
    h2 {
      font-size: 40px;
    }
  }

}

.footer {
  display: flex;
  padding: calc(var(--base-space) * 1.5);
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background: var(--Color-Neutral-black, #000);
  color: var(--Trigon-Light, #DDE7DA);
  a {
    text-decoration: underline;
    cursor: pointer;
  }
  p {
    margin: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      display: inline;
    }
  }
  a {
    text-decoration: none;
    color: var(--Trigon-Light, #DDE7DA);
    padding: 0 calc(var(--base-space)/2) 0 5px;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    text-align: center;
    padding: calc(var(--base-space)* 3);
    ul {
      padding: 0 calc(var(--base-space)/2);

      li {
        display: block;
        white-space: nowrap;
      }
    }
    .mobile-row {
      display: block;
      text-align: center;
    }
  }
}

.graph {
  padding: calc(var(--base-space) * 2) calc(var(--base-space) * 1.5) var(--base-space);

  iframe {
    width: 100%;
    height: 750px;
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .graph {
    iframe {
      height: 600px;
    }
  }
}

.dark-green-bg {
  background: var(--Trigon-Dark, #032705);
  color: var(--Trigon-Light, #DDE7DA);
}

.contact-us {
  display: flex;
  padding: calc(var(--base-space) * 3) calc(var(--base-space) * 1.5);
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  background: var(--Trigon-Dark, #032705);
  color: var(--Trigon-Light, #DDE7DA);
  a {
    color: var(--Trigon-Light, #DDE7DA);
    text-decoration: underline;
  }
  h2 {
    font-family: Coconat;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-top: 0;
  }
  form {
    .section{
      display: flex;
      padding: 6px 6px 6px 8px;
      justify-content: space-between;
      align-items: center;
      border-radius: 48px;
      border: solid 2px transparent;
      background: #FFF;
      margin-top: var(--base-space);
      &.invalid {
        background-color: #d7abab;
      }
      button,
      input {
        vertical-align: middle;
        margin: 0;
        color: var(--Trigon-Dark, #032705);
      }
      input {
        border: none;
        background: transparent;
      }
    }
    .section-check {
      padding: calc(var(--base-space) / 2) 0;
      display: block;

      .sq {
        display: inline-block;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin: 0 12px 0 0;
        vertical-align: middle;
        border: 2px solid #000;
        background-color: var(--Trigon-Light, #DDE7DA);
      }
      &.invalid {
        .sq {
          background: #d7abab;
        }
      }
      &.checked {
        .sq {
          background: #000 url(./check.svg) no-repeat center center;
          background-size: 80%;
        }
      }
    }
  }
}


@media only screen and (max-width: 767px) {
  .contact-us {
    padding: calc(var(--base-space) * 4) calc(var(--base-space) * 1.5);
    .container {
      padding: 0;
    }
    h2 {
      font-size: 40px;
    }
    form {
      .section{
        display: flex;
        flex-direction: column;
        padding: 0;
        border-radius: 0;
        background: none;
        margin-top: var(--base-space);
        border-color: transparent;
        &.invalid {
          background-color: transparent;
          input {
            background-color: #d7abab;
          }
        }
        button,
        input {
          display: block;
          width: 100%;
          margin: 0 auto 20px;
          box-sizing: border-box;
          color: var(--Trigon-Dark, #032705);
          height: var(--base-space);
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          border-radius: 40px;
          padding: 0 30px;
        }
        button {
          background: none;
          color: var(--Trigon-Light, #DDE7DA);
        }
        input {
          border: none;
          background-color: #fff;
          border: solid 2px #fff;
        }
      }
      .section-check {
        margin-top: 0;
        .checkbox {
          input[type=checkbox] {
            & + span {
              line-height: 24px;
              &::before {

              }
            }

            &:checked + span {
              &::before {
                background: var(--Trigon-Dark, #032705);
              }
            }
          }
        }
      }
    }
  }
}

.instructions {

  border-radius: 16px;
  border: 1px solid var(--Trigon-Light, #DDE7DA);
  padding: 56px 16px;

  .container {
    margin: 0;
    padding: 0;
  }
  .tabs {
    text-align: center;
    padding: 0 0 var(--base-space);
    button {
      display: inline-block;
      height: var(--base-space);
      line-height: var(--base-space);
      padding: 8px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 48px;
      background: var(--Trigon-Light, #DDE7DA);
      color: var(--Trigon-Dark, #032705);
      border: none;
      margin: 0 calc(var(--base-space)/4);
      margin-bottom: 8px;
      &[aria-selected="true"] {
        background: var(--Trigon-Dark, #032705);
        color: var(--Trigon-Light, #DDE7DA);
      }
    }
  }
  .steps {
    div {
      background: rgba(221, 231, 218, 0.20);
      padding: calc(var(--base-space) / 2);
      border-top: 1px solid var(--Trigon-Light, #DDE7DA);
      cursor: pointer;

      p {
        margin: 16px 0 0;
      }

      &:first-child {
        border-radius: 16px 16px 0 0;
        border-top: none;
      }
      &:last-child {
        border-radius: 0 0 16px 16px;
      }

      &.active {
        color: var(--Trigon-Light, #DDE7DA);
        background: var(--Trigon-Dark, #032705);
        cursor: default;
      }
      h3 {
        margin: 0;
        font-size: 32px;
        font-family: Coconat;
        font-style: normal;
        font-weight: 400;
        margin: 10px 0px;
      }
    }
  }
  .carousel {
    .carousel-status {
      display: none;
    }
    .thumbs-wrapper {
      margin: 0;
    }
    .thumbs {
      margin: 15px 0;
      padding: 0 0 0 calc(var(--base-space) / 2);
    }
    .thumb {
      max-width: 0;
      max-height: 0;
      border-color: var(--Trigon-Light, #DDE7DA);
      padding: 0;
      border-radius: 50%;
      transform: scale(1.5);
      margin-left: 8px;
    }
    .control-dots {
      display: none;
      visibility: hidden;
    }
    &.carousel-slider {
      overflow: initial;
      .control-arrow {
        position: absolute;
        top: 100%;
        margin-top: 16px;
        right: 0;
        margin-right: 24px;
        width: 40px;
        height: 25px;
        padding: 5px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        border: 1px solid var(--Trigon-Light, #DDE7DA);
        opacity: 1;
        display: block;

        &::before {
          border: none;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: url(./arrow.svg) no-repeat center center;
          margin: 0;
        }
        &.control-prev {
          left: auto;
          right: 48px;
          &::before {
            transform: rotate(180deg);
          }
        }
        &.control-disabled {
          border-color: #CCC;
          background: #CCC;
        }
      }
    }
  }
}


@media only screen and (max-width: 767px) {
  .instructions {
    border-radius: 0;
    border: none;
    padding: 0;

    .carousel-root,
    .steps {
      padding: 0 20px;
    }
    .tabs {
      padding: 0 var(--base-space);
      button {
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin: 0 0 20px;
        padding: calc(var(--base-space) * 2);
        font-size: 20px;
      }
    }
    .steps {
      div {
        padding: calc(var(--base-space) * 2);
      }
    }
    .carousel {
      .control-dots {
        transform: scale(2);
        transform-origin: center center;
      }
      &.carousel-slider {
        .control-arrow {
          transform: scale(1.5);
          transform-origin: center center;
          &.control-prev {
            right: 68px;
          }
        }
      }
    }
  }
}

.invest {
  img {
    margin: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .invest {
    margin: var(--base-space);
    img {
      margin: 0 auto;
    }
  }
}

.privacy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  color: #000;
  z-index: 10000;
  padding: var(--base-space);
  box-sizing: border-box;
  overflow: auto;
  .close {
    display: block;
    width: 50px;
    height: 50px;
    background: var(--Trigon-Light, #DDE7DA);
    text-align: center;
    line-height: 50px;
    border-radius: 100%;
    margin-bottom: -30px;
    margin: 0 0 0 auto;
    position: sticky;
    top: 0;
    font-size: 40px;
    z-index: 100;
    cursor: pointer;
  }
  a {
    color: #000;
  }
}
@media only screen and (max-width: 767px) {
  .privacy {
    padding: calc( var(--base-space) * 2 );

  }
}